import React from 'react'
import './YouTube.css'

function YouTube() {
  return (
    <div>
        <h1 className = "youtube-title">YouTube</h1>
        <div>Coming Soon!</div>
    </div>
  )
}

export default YouTube